<template>
  <div class="flex flex-col items-center w-full h-full">
    <div class="flex flex-col w-full h-full overflow-auto">
      <div
        class="flex flex-col justify-center flex-shrink-0 w-full h-full p-2 text-gray-800 bg-white shadow"
      >
        <div class="flex flex-col flex-1 h-full p-2 pl-5 space-y-2 text-left">
          <div
            class="flex flex-col items-center justify-center h-full py-3 space-y-3 text-lg"
          >
            <h1 class="text-4xl font-extrabold">Coming Soon</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
